import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { fadeOnBeforeCompile } from '../utils/fadeMaterial'
import { useFrame } from '@react-three/fiber';

export function CloudTwo({ opacity, sceneOpacity, ...props }) {
    const { nodes, materials } = useGLTF('./models/cloudTwo.glb');

    const materialRef = useRef();
    
    useFrame(() => {
        materialRef.current.opacity = sceneOpacity.current;
    })

    return (
        <group {...props} dispose={null}>
            <mesh 
                geometry={nodes.Mball008.geometry} 
                position={[2.427, 0.702, -1.384]} 
            >
                <meshStandardMaterial 
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent 
                    ref={materialRef}
                />
            </mesh>
        </group>
    )
}

useGLTF.preload('./models/cloudTwo.glb')
