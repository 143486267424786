import { Canvas } from "@react-three/fiber";
import { Experience } from "./components/Experience";
import { ScrollControls, useScroll } from "@react-three/drei";
import { EffectComposer, Noise } from "@react-three/postprocessing";
import { Overlay } from "./components/Overlay";
import { usePlay } from "./contexts/Play";
import Navbar from "./components/Navbar";
import { useEffect, useRef, useState } from "react";
import Ending from "./components/Ending";

function App() {
    const { play, end } = usePlay();

    const [ scrollData, setScrollData ] = useState({offset: 0});

    return (
        <>
            <Navbar scrollData={scrollData} />
            <Canvas>
            <color attach="background" args={["#ececec"]} />
            <ScrollControls 
                pages={play && !end ? 20 : 0} 
                damping={1}
                style={{
                top: "10px",
                left: "0px",
                bottom: "10px",
                right: "10px",
                width: "auto",
                height: "auto",
                animation: "fadeIn 2.4s ease-in-out 1.2s forwards",
                opacity: 0
                }}
            >
                <Experience setScrollData={setScrollData} scrollData={scrollData} />
            </ScrollControls>   
            <EffectComposer>
                <Noise opacity={0.08} />
            </EffectComposer>
            </Canvas>
            <Overlay />
            {/* <Ending /> */}
        </>
    );
}

export default App;