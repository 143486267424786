import React, { useEffect, useState } from "react";
import { Text } from "@react-three/drei";
import { animated, useSpring } from "@react-spring/three";
import $ from "jquery";
import { usePlay } from "../contexts/Play";
import { fadeOnBeforeCompile } from "../utils/fadeMaterial";

const AnimatedText = animated(Text);

export default function ClickableText({ text, link, serif, index, yPos, rotate, direction }) {
    const fontSize = serif ? 0.52 : 0.3;
    const [hover, setHover] = useState(false);
    const spring = useSpring({
        color: hover ? '#ccccccff' : '#ffffff',
        fontSize: hover ? fontSize * 1.05 : fontSize,
        outlineWidth: hover ? 0.01 : 0,
        outlineColor: hover ? "#000000" : "#ffffff"
    });

    const { play } = usePlay();

    useEffect(() => {
        document.body.style.cursor = hover ? 'pointer' : 'auto';
    }, [hover]);

    return (
        <AnimatedText
            key={index}
            color={spring.color}
            rotation={rotate}
            anchorX={direction}
            anchorY={serif ? "bottom" : "top"}
            font={serif ? "./fonts/DMSerifDisplay-Regular.ttf" : "./fonts/Inter-Regular.ttf"}
            textAlign={"center"}
            fontSize={spring.fontSize}
            maxWidth={10}
            position-y={yPos ? yPos : 0}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}
            onClick={() => window.open(link, "_blank")}
            outlineWidth={spring.outlineWidth}
            outlineColor={spring.outlineColor}
        >
            {text}
            <meshStandardMaterial  
                onBeforeCompile={fadeOnBeforeCompile}
                envMapIntensity={2}
                transparent 
                emissive="#9692dd"
                opacity={play ? 1 : 0}
            />
        </AnimatedText>
    )
}
