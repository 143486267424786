import { Text } from "@react-three/drei";
import { fadeOnBeforeCompile, fadeOnBeforeCompileFlat } from "../utils/fadeMaterial";
import { usePlay } from "../contexts/Play";
import ClickableText from "./ClickableText";

export const TextSection = ({ type, info, direction, rotate, ...props }) => {
    const { play } = usePlay();

    const bigLineHeight = 1.5;
    const lineHeight = 0.7;
    
    function makeSerifText(text, yPos, index, direction, rotate) {
        const rotateArray = rotate ? [0, rotate, 0] : [0, 0, 0];
        return (
            <Text
                key={index}
                color="white"
                anchorX={direction}
                fontSize={0.52}
                maxWidth={10}
                rotation={rotateArray}
                font={"./fonts/DMSerifDisplay-Regular.ttf"}
                position-y={yPos}
            >
                {text}
                <meshStandardMaterial  
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent 
                    emissive={"white"}
                    opacity={play ? direction === "center" ? 0.2 : 1 : 0}
                />
            </Text>
        )
    }

    function makeSansSerifText(text, yPos, index, direction, rotate) {
        const rotateArray = rotate ? [0, rotate, 0] : [0, 0, 0];
        return (
            <Text
                key={index}
                color="white"
                anchorX={direction}
                fontSize={0.3}
                maxWidth={10}
                rotation={rotateArray}
                font={"./fonts/Inter-Regular.ttf"}
                position-y={yPos}
            >
                {text}
                <meshStandardMaterial  
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent 
                    emissive={"white"}
                    opacity={play ? direction === "center" ? 0.2 : 1 : 0}
                />
            </Text>
        )
    }

    function makeClickableText(info, totalLineHeight, direction, index, rotate) {
        const rotateArray = rotate ? [0, rotate, 0] : [0, 0, 0];
        return (
            <ClickableText 
                text={info.text} 
                link={info.link} 
                serif={info.serif} 
                index={index} 
                yPos={totalLineHeight} 
                direction={direction}
                rotate={rotateArray}
            />
        )
    }

    // type 0 for serif, 1 for sans serif, 2 for clickable text
    let totalLineHeight = 1;
    const allText = type.map((number, index) => {
        if (index !== 0 && (type[index - 1] === 0)) {
            totalLineHeight -= 0.6;
        }
        else if (type[index] === 2 && info[index].serif) {
            totalLineHeight -= 0.9;
        }
        else {
            totalLineHeight -= 0.5;
        }

        let text;
        if (number === 0) {
            text = makeSerifText(info[index], totalLineHeight, index, direction, rotate);
        }
        else if (number === 1) {
            text = makeSansSerifText(info[index], totalLineHeight, index, direction, rotate);
        }
        else {
            // clickable text requires this properties
            // const { text, link, serif } = info[index];
            text = makeClickableText(info[index], totalLineHeight, direction, index, rotate);
        }

        return text;
    });

    // let text;
    // if (type === 0) {
    //     text = makeSerifText(info);
    // }
    // else if (type === 1) {
    //     text = makeSansSerifText(info);
    // }
    // else {
    //     text = makeClickableText(info);
    // }

    return (
        <group {...props}>
            {allText}
        </group>
    );
};