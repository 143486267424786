import React, { useState, useEffect } from "react";
import { useProgress } from "@react-three/drei"
import { usePlay } from "../contexts/Play";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";

export const Overlay = () => {
    const { progress } = useProgress();
    const { play, setPlay, hasScroll, end, setEnd, setReset } = usePlay();

    const [showResume, setShowResume] = useState(false);

    function restart() {
        window.location.reload();
    }

    function openLink(link) {
        window.open(link, "_blank");
    }

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth); // Initialize with the current window width

    useEffect(() => {
        // Function to update the width
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setViewportWidth(newWidth); 
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to ensure it runs once on mount

    return (
        <div className={`overlay ${play & !end ? "overlay--disable" : ""} ${hasScroll ? "overlay--scrolled" : ""}`}>
            <div className={`loader ${progress === 100 ? "loader--disappear" : ""}`} />
            {
                progress === 100 && (
                <div className={`intro ${play ? "intro--disappear" : ""}`}>
                    <p className={`intro_text ${play ? "disappear" : ""}`}>Hello, my name is</p>
                    <h1 className="logo">
                        JAMES SONG
                    </h1>
                    <p className="intro__scroll">SCROLL DOWN TO BEGIN</p>
                    {viewportWidth < 700 && <p className="intro__scroll_2">USE DESKTOP FOR BETTER EXPERIENCE</p>}
                    {!end && !play && <button 
                        className="explore" 
                        onClick={() => {
                            setPlay(true);
                        }}
                    >
                        Start
                    </button>}
                </div>
                )
            }
            <div className={`outro ${end ? "outro--appear"  : ""}`}>
                <p className="outro__text">
                    Thank you for visiting
                </p>
                {/* <p className="outro__text outro_button" onClick={() => restart()}>Restart the Experience</p> */}
                <div className="flex flex-col align-center outro_button" onClick={() => restart()}>
                    <img 
                        draggable="false"
                        className="restart_button"
                        alt="restart button"
                        src="/images/restart.svg"
                    />
                    <p className="outro__text">Restart the Experience</p>
                </div>

                {/* SOCIALS */}
                <div className="line" />
                <div className="flex flex-row gap align-center social_button" onClick={() => openLink("https://github.com/jsong552")}>
                    <img 
                        draggable="false"
                        className="social_icon"
                        alt="github icon"
                        src="/images/github.svg"
                    />
                    <p className="outro__text_small">GitHub</p>
                </div>
                <div className="flex flex-row gap align-center social_button" onClick={() => openLink("https://www.linkedin.com/in/james-song17/")}>
                    <img 
                        draggable="false"
                        className="social_icon"
                        alt="linkedin icon"
                        src="/images/linkedin.svg"
                    />
                    <p className="outro__text_small">LinkedIn</p>
                </div>
                <div className="flex flex-row gap align-center social_button" onClick={() => window.location.href = "mailto:james.song017@gmail.com"}>
                    <img 
                        draggable="false"
                        className="social_icon"
                        alt="email icon"
                        src="/images/mail.svg"
                    />
                    <p className="outro__text_small">Email</p>
                </div>
                <div className="flex flex-row gap align-center social_button" onClick={() => setShowResume(true)}>
                    <img 
                        draggable="false"
                        className="social_icon"
                        alt="resume icon"
                        src="/images/resume.svg"
                    />
                    <p className="outro__text_small">View my Resume</p>
                </div>

                <AnimatePresence>
                    {showResume && (
                        <motion.div 
                            className="resume_container" 
                            onClick={() => setShowResume(false)}
                            initial={{
                                opacity: 0,
                                y: "100vh"
                            }}
                            animate={{
                                opacity: 1,
                                y: 0,
                                transition: {
                                    duration: 1,
                                    ease: [0.1, 0.81, 0.38, 1]
                                }
                            }}
                            exit={{
                                opacity: 0,
                                y: "100vh",
                                transition: {
                                    duration: 1,
                                    ease: [0.1, 0.81, 0.38, 1]
                                }
                            }}
                        >
                            <iframe 
                                src="/Resume2024-09.pdf"
                                className="resume"
                            />
                        </motion.div>
                    )}
                </AnimatePresence>



            </div>
        </div>
    )
}