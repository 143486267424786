import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { fadeOnBeforeCompile } from '../utils/fadeMaterial'
import { useFrame } from '@react-three/fiber'

export function Haskell({ sceneOpacity, opacity, ...props }) {
    const { nodes, materials } = useGLTF('./models/haskell.glb');

    const materialRef = useRef();

    useFrame(() => {
        materialRef.current.opacity = sceneOpacity.current;
    });

    return (
        <group {...props} dispose={null}>
            <mesh geometry={nodes.Curve.geometry} material={materials['SVGMat.074']} scale={260.192} >
                <meshStandardMaterial  
                    ref={materialRef}
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent 
                    opacity={opacity}
                />
            </mesh>
            <mesh geometry={nodes.Curve002.geometry} material={materials['SVGMat.075']} scale={260.192} >
                <meshStandardMaterial  
                    ref={materialRef}
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent 
                    opacity={opacity}
                />
            </mesh>
            <mesh geometry={nodes.Curve003.geometry} material={materials['SVGMat.076']} scale={260.192} >
                <meshStandardMaterial  
                    ref={materialRef}
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent 
                    opacity={opacity}
                />
            </mesh>
        </group>
    )
}

useGLTF.preload('./models/haskell.glb')
