import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { fadeOnBeforeCompile } from '../utils/fadeMaterial'
import { useFrame } from '@react-three/fiber'

export function Html({ opacity, sceneOpacity, ...props }) {
  const { nodes, materials } = useGLTF('./models/html.glb');

  const materialRef = useRef();

  useFrame(() => {
    materialRef.current.opacity = sceneOpacity.current;
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Curve.geometry} material={nodes.Curve.material} scale={7.298} >
        <meshStandardMaterial  
          ref={materialRef}
          onBeforeCompile={fadeOnBeforeCompile}
          envMapIntensity={2}
          transparent 
          opacity={opacity}
        />
      </mesh>
      <mesh geometry={nodes.Curve001.geometry} material={materials['SVGMat.030']} scale={7.298} >
        <meshStandardMaterial  
          ref={materialRef}
          onBeforeCompile={fadeOnBeforeCompile}
          envMapIntensity={2}
          transparent 
          opacity={opacity}
        />
      </mesh>
      <mesh geometry={nodes.Curve002.geometry} material={materials['SVGMat.031']} scale={7.298} >
        <meshStandardMaterial  
          ref={materialRef}
          onBeforeCompile={fadeOnBeforeCompile}
          envMapIntensity={2}
          transparent 
          opacity={opacity}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload('./models/html.glb')
