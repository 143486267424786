import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { fadeOnBeforeCompile } from '../utils/fadeMaterial';
import { useFrame } from '@react-three/fiber';

export function Python({ opacity, sceneOpacity, ...props }) {
    const { nodes, materials } = useGLTF('./models/python_black.glb');

    const materialRef = useRef();

    useFrame(() => {
        materialRef.current.opacity = sceneOpacity.current;
    });

    return (
        <group {...props} dispose={null}>
            <mesh geometry={nodes.Curve.geometry} >
                <meshStandardMaterial 
                    ref={materialRef}
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent
                    opacity={opacity}
                />
            </mesh>
            <mesh geometry={nodes.Curve001.geometry} >
                <meshStandardMaterial 
                    ref={materialRef}
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent
                    opacity={opacity}
                />
            </mesh>
        </group>  
    )
}

useGLTF.preload('./models/python_black.glb')
