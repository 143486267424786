import React, { createContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { OrbitControls, Float, Line, PerspectiveCamera, useScroll, Text } from "@react-three/drei";
import { Background } from "./Background";
import { Airplane } from "./Airplane";
import { Cloud } from "./Cloud";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { animated, useSpring } from "@react-spring/three";
import ClickableText from "./ClickableText";
import { TextSection } from "./TextSection";
import { gsap } from "gsap/gsap-core";
import { fadeOnBeforeCompile } from "../utils/fadeMaterial";
import { CloudTwo } from "./CloudTwo";
import { usePlay } from "../contexts/Play";
import { Python } from "./Python";
import { Java } from "./Java";
import { Javascript } from "./Javascript";
import { Typescript } from "./Typescript";
import { ReactLogo } from "./ReactLogo";
import { Next } from "./Next";
import { Html } from "./Html";
import { Css } from "./Css";
import { Tailwind } from "./Tailwind";
import { Framer } from "./Framer";
import { Firebase } from "./Firebase";
import { Jquery } from "./Jquery";
import { Git } from "./Git";
import { Bash } from "./Bash";
import { Node } from "./Node";
import { Haskell } from "./Haskell";
import { C } from "./C";

const LINE_NB_POINTS = 1000;
const CURVE_DISTANCE = 300;
const CURVE_AHEAD_CAMERA = 0.008;
const CURVE_AHEAD_AIRPLANE = 0.02;
const AIRPLANE_MAX_ANGLE = 35;
const FRICTION_DISTANCE = 60;   // this number controls the distance in which the camera starts to move (when getting close to text)

export function Experience({ scrollData, setScrollData }) {

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth); // Initialize with the current window width

    useEffect(() => {
        // Function to update the width
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setViewportWidth(newWidth); 
        };

        // Add event listener for window resize
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to ensure it runs once on mount

    const curvePoints = useMemo(() => [
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 0, -CURVE_DISTANCE),
        new THREE.Vector3(100, 0, -2 * CURVE_DISTANCE),
        new THREE.Vector3(-100, 0, -3 * CURVE_DISTANCE),
        new THREE.Vector3(100, 0, -4 * CURVE_DISTANCE),
        new THREE.Vector3(0, 0, -5 * CURVE_DISTANCE),
        new THREE.Vector3(0, 0, -6 * CURVE_DISTANCE),
        new THREE.Vector3(0, 0, -7 * CURVE_DISTANCE),
    ], []);

    const sceneOpacity = useRef(0);
    const lineMaterialRef = useRef();

    const curve = useMemo(() => {
        return new THREE.CatmullRomCurve3(
            curvePoints,
            false,
            "catmullrom",
            0.5
        );
    }, []);


    // RENDER YOUR TEXTS HERE
    // type 0 for serif, 1 for sans serif, 2 for clickable text
    const textSections = useMemo(() => {
        return [
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[0].x ,
                    curvePoints[0].y,
                    curvePoints[0].z - 10,
                ),
                direction: "center",
                type: [0],
                info: [
                    "EDUCATION",
                ]
            },
            {
                cameraRailDist: 0.5,
                position: new THREE.Vector3(
                    curvePoints[0].x - 6.5,
                    curvePoints[0].y,
                    curvePoints[0].z - 120,
                ),
                direction: "right",
                type: [1, 0, 1, 0],
                info: [
                    "I was born and raised in",
                    "Canada",
                    "and I grew up in the Canadian city of",
                    "St. John's, Newfoundland"
                ]
            },
            {
                cameraRailDist: 0.5,
                position: new THREE.Vector3(
                    curvePoints[0].x - 3,
                    curvePoints[0].y + 1,
                    curvePoints[0].z - 120,
                ),
                direction: "left",
                type: [1, 0, 0, 1, 1],
                info: [
                    "Here, I attended",
                    "Memorial University",
                    "of Newfoundland",
                    "simultaneously with my Grade 12 Year",
                    "where I focused on topics in mathematics"
                ]
            },
            {
                cameraRailDist: 0.5,
                position: new THREE.Vector3(
                    curvePoints[0].x - 3,
                    curvePoints[0].y - 2,
                    curvePoints[0].z - 120,
                ),
                direction: "left",
                type: [1, 0],
                info: [
                    "I finished with a GPA of",
                    "4.0/4.0",
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[0].x - 5.5,
                    curvePoints[0].y,
                    curvePoints[0].z - 190
                ),
                direction: "left",
                type: [1, 0, 1, 0, 0],
                info: [
                    "Now, I am an aspiring",
                    "Software Engineer", 
                    "currently studying", 
                    "Computer Science at",
                    "Queen's University"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[0].x - 6,
                    curvePoints[0].y + 1,
                    curvePoints[0].z - 270
                ),
                direction: "right",
                type: [1, 0, 1, 0, 1, 0],
                info: [
                    "I am expected to",
                    "graduate in 2026", 
                    "from Queen's University with a major", 
                    "in Computing (Honours)",
                    "and a minor",
                    "in Statistics (Science)"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[1].x + 13,
                    curvePoints[1].y + 0.3,
                    curvePoints[1].z - 50
                ),
                direction: "right",
                type: [1, 0, 1, 0, 0],
                rotate: -0.55,
                info: [
                    "I have a",
                    "4.3 / 4.3 GPA",
                    "placed on the",
                    "Dean's Honour List",
                    "with distinction"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[1].x + 17,
                    curvePoints[1].y + 1,
                    curvePoints[1].z - 50
                ),
                direction: "left",
                type: [0, 1, 1, 1, 1, 1, 1],
                rotate: -0.55,
                info: [
                    "Some of my relevant coursework:",
                    "Data structures and Algorithms",
                    "Database Management Systems",
                    "Object Oriented Programming",
                    "Linear and Advanced Data Analysis",
                    "Software Quality Assurance",
                    "Operating Systems"
                ]
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[1].x + 37.2,
                    curvePoints[1].y,
                    curvePoints[1].z - 100
                ),
                direction: "center",
                rotate: -0.55,
                type: [0],
                info: [
                    "EXPERIENCE",
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[1].x + 83,
                    curvePoints[1].y + 2,
                    curvePoints[1].z - 190
                ),
                direction: "left",
                rotate: -0.4,
                type: [1, 0, 1, 1, 0, 0],
                info: [
                    "I have been working as a",
                    "Teaching Assistant",
                    "for multiple courses at Queen's University",
                    "including courses and topics such as:",
                    "Computer Science (CISC 121)",
                    "Cognitive Science (COGS 100)"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[1].x + 83,
                    curvePoints[1].y - 2,
                    curvePoints[1].z - 190
                ),
                direction: "left",
                rotate: -0.4,
                type: [1, 1, 1, 1],
                info: [
                    "This involved grading assignments and exams,",
                    "providing feedback to students,",
                    "holding tutorials / coding hours,",
                    "and solving any inquiries that students may have"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[2].x - 2,
                    curvePoints[2].y + 1,
                    curvePoints[2].z
                ),
                direction: "right",
                rotate: 0.2,
                type: [1, 0, 1],
                info: [
                    "I am currently working to create",
                    "PropertyPadPro",
                    "for a client"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[2].x - 2,
                    curvePoints[2].y - 1.5,
                    curvePoints[2].z
                ),
                direction: "right",
                rotate: 0.2,
                type: [1, 1, 1],
                info: [
                    "This is an app designed for landlords to make",
                    "it easier to interact with tenants,",
                    "handle payments, and various other services"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[2].x - 56,
                    curvePoints[2].y + 1.5,
                    curvePoints[2].z - 100
                ),
                direction: "left",
                rotate: 0.7,
                type: [1, 0, 1, 0],
                info: [
                    "I've worked as a",
                    "Full-Stack Software Developer",
                    "from January 2024 to April 2024 for the",
                    "Queen's Web Development Club"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[2].x - 56,
                    curvePoints[2].y - 1,
                    curvePoints[2].z - 100
                ),
                direction: "left",
                rotate: 0.7,
                type: [1, 2, 1, 0],
                info: [
                    "Here, with a team of 3 other developers, we created",
                    {
                        serif: true,
                        text: "Jack.org Queen's Chapter",
                        link: "https://jack-org.vercel.app/"
                    },
                    "using technologies such as",
                    "NextJS, Tailwind, and NodeJS"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[2].x - 128,
                    curvePoints[2].y + 1,
                    curvePoints[2].z - 170
                ),
                direction: "right",
                rotate: 0.75,
                type: [1, 0, 1],
                info: [
                    "I have been chosen by COMPSA to be a",
                    "Python Tutorial Leader",
                    "for the 2023-2024 academic year"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[2].x - 128,
                    curvePoints[2].y - 1,
                    curvePoints[2].z - 170
                ),
                direction: "right",
                rotate: 0.75,
                type: [1, 1, 1, 1],
                info: [
                    "During these tutorials, I prepared",
                    "and covered numerous topics such as",
                    "searching and sorting algorithms, recursion,",
                    "coding practices, and time complexity"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 11.5,
                    curvePoints[3].y + 1.5,
                    curvePoints[3].z + 50
                ),
                direction: "right",
                rotate: 0.5,
                type: [1, 0],
                info: [
                    "As of currently, I've participated in",
                    "one hackathon"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 11.5,
                    curvePoints[3].y,
                    curvePoints[3].z + 50
                ),
                direction: "right",
                rotate: 0.5,
                type: [1, 0, 1, 0],
                info: [
                    "In February of 2024, I competed in",
                    "QHacks at Queen's",
                    "where I worked in a team of 3 people to create",
                    "Kingston Marketplace"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 11.5,
                    curvePoints[3].y - 2.5,
                    curvePoints[3].z + 50
                ),
                direction: "right",
                rotate: 0.5,
                type: [2],
                info: [
                    {
                        serif: false,
                        text: "View the Source Code",
                        link: "https://github.com/AdwaitSri/Kingston-Marketplace"
                    }
                ]
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[3].x + 3.5,
                    curvePoints[3].y,
                    curvePoints[3].z - 25
                ),
                direction: "center",
                rotate: -0.27,
                type: [0],
                info: [
                    "PROJECTS"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 42.5,
                    curvePoints[3].y + 3,
                    curvePoints[3].z - 90
                ),
                direction: "left",
                rotate: -0.7,
                type: [0, 1, 1, 1],
                info: [
                    "1. Skule Tracker (overview)",
                    "Since starting university, I've noticed that the responsibility",
                    "of keeping track of my academics was greatly increased - whether",
                    "that be grades, due dates, or exam preparation"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 42.5,
                    curvePoints[3].y + 0.5,
                    curvePoints[3].z - 90
                ),
                direction: "left",
                rotate: -0.7,
                type: [1, 1, 1, 1],
                info: [
                    "Originally, I used physical calendars or just pure memory",
                    "to keep track of what I need. I quickly realized that this",
                    "was not going to work anymore, and I wanted to have a piece",
                    "of software where I could have everything I needed in one place"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 42.5,
                    curvePoints[3].y - 2,
                    curvePoints[3].z - 90
                ),
                direction: "left",
                rotate: -0.7,
                type: [1, 1, 1, 1],
                info: [
                    "So, I created Skule Tracker - an app that students can use",
                    "to organize their academics all in one place, where averages,",
                    "required grades, and GPAs are all automatically calculated",
                    "along with an automatically generated interactive calendar",
                    "based on assessments created within an account"
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 120,
                    curvePoints[3].y + 2,
                    curvePoints[3].z - 180
                ),
                direction: "right",
                rotate: -0.8,
                type: [0, 1, 0],
                info: [
                    "Skule Tracker (technicalities)",
                    "To create this, I used the framework",
                    "ReactJS with React Router",
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 120,
                    curvePoints[3].y,
                    curvePoints[3].z - 180
                ),
                direction: "right",
                rotate: -0.8,
                type: [1, 0],
                info: [
                    "To create the front-end, I used technologies such as",
                    "HTML/CSS, TailwindCSS, Framer Motion",
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 120,
                    curvePoints[3].y - 1.5,
                    curvePoints[3].z - 180
                ),
                direction: "right",
                rotate: -0.8,
                type: [1, 0],
                info: [
                    "To create the back-end, I used technologies such as",
                    "JavaScript (ES6+), NodeJS, and Firebase",
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[3].x + 120,
                    curvePoints[3].y - 3,
                    curvePoints[3].z - 180
                ),
                direction: "right",
                rotate: -0.8,
                type: [2],
                info: [
                    {
                        serif: false,
                        text: "View the Finished Project",
                        link: "https://www.skule.app/"
                    },
                    // {
                    //     serif: false,
                    //     text: "View the Source Code",
                    //     link: "https://www.skule.app/"                  // change this sht later
                    // }
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[4].x - 12,
                    curvePoints[4].y + 2,
                    curvePoints[4].z + 40
                ),
                direction: "left",
                rotate: -0.5,
                type: [0, 1, 1, 1],
                info: [
                    "2. Scale Shuffler (overview)",
                    "Apart from programming and software related things,",
                    "I also enjoy playing the piano and have built a community",
                    "of pianist peers over the years"
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[4].x - 12,
                    curvePoints[4].y - 0.5,
                    curvePoints[4].z + 40
                ),
                direction: "left",
                rotate: -0.5,
                type: [1, 1],
                info: [
                    "There are extensive piano exams that are taken to receive certificates",
                    "which involves memorizing and practicing over 100 scales",
                ]
            },
            {
                cameraRailDist: 1.5,
                position: new THREE.Vector3(
                    curvePoints[4].x - 12,
                    curvePoints[4].y - 2,
                    curvePoints[4].z + 40
                ),
                direction: "left",
                rotate: -0.5,
                type: [1, 1, 1],
                info: [
                    "Some of my peers requested for me to create an app",
                    "tailored for their needs to aid them in practicing for their exams,",
                    "so I created Scale Shuffler to solve this real-world problem",
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[4].x - 17,
                    curvePoints[4].y + 2,
                    curvePoints[4].z - 100
                ),
                direction: "right",
                rotate: 0.4,
                type: [0, 1, 0],
                info: [
                    "Scale Shuffler (technicalities)",
                    "To create this, I used the framework",
                    "ReactJS with React Router",
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[4].x - 17,
                    curvePoints[4].y,
                    curvePoints[4].z - 100
                ),
                direction: "right",
                rotate: 0.4,
                type: [1, 0],
                info: [
                    "To create the front-end, I used technologies such as",
                    "HTML/CSS, TailwindCSS, Framer Motion",
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[4].x - 17,
                    curvePoints[4].y - 1.5,
                    curvePoints[4].z - 100
                ),
                direction: "right",
                rotate: 0.4,
                type: [1, 0],
                info: [
                    "To create the back-end, I used technologies such as",
                    "TypeScript, NodeJS, and Firebase",
                ]
            },
            {
                cameraRailDist: -1.5,
                position: new THREE.Vector3(
                    curvePoints[4].x - 17,
                    curvePoints[4].y - 3,
                    curvePoints[4].z - 100
                ),
                direction: "right",
                rotate: 0.4,
                type: [2],
                info: [
                    {
                        serif: false,
                        text: "View the Finished Project",
                        link: "https://scaleshuffler.netlify.app/"
                    },
                    // {
                    //     serif: false,
                    //     text: "View the Source Code",
                    //     link: "https://scaleshuffler.netlify.app/"                  // change this sht later
                    // }
                ]
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[4].x - 58,
                    curvePoints[4].y,
                    curvePoints[4].z - 190
                ),
                direction: "center",
                rotate: 0.5,
                type: [0],
                info: [
                    "MY SKILLS"
                ]
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[4].x - 92,
                    curvePoints[4].y,
                    curvePoints[4].z - 260
                ),
                direction: "center",
                rotate: 0.5,
                type: [0],
                info: [
                    "Python"
                ]
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x + 3,
                    curvePoints[5].y - 1,
                    curvePoints[5].z + 5 
                ),
                direction: "center",
                rotate: 0.5,
                type: [0],
                info: [
                    "Java"
                ],
                scale: 2.5
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x - 6,
                    curvePoints[5].y - 1,
                    curvePoints[5].z - 20 
                ),
                direction: "center",
                rotate: 0.5,
                type: [0],
                info: [
                    "JavaScript ES6+"
                ]
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x - 4,
                    curvePoints[5].y ,
                    curvePoints[5].z - 75 
                ),
                direction: "center",
                rotate: 0.2,
                type: [0],
                info: [
                    "TypeScript"
                ],
                scale: 2
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x - 10,
                    curvePoints[5].y ,
                    curvePoints[5].z - 125
                ),
                direction: "center",
                rotate: 0.2,
                type: [0],
                info: [
                    "React"
                ],
                scale: 3
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x - 1.5,
                    curvePoints[5].y - 1,
                    curvePoints[5].z - 175
                ),
                direction: "center",
                rotate: 0.2,
                type: [0],
                info: [
                    "Next"
                ],
                scale: 1.5
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x - 8,
                    curvePoints[5].y - 0,
                    curvePoints[5].z - 180
                ),
                direction: "center",
                rotate: 0.2,
                type: [0],
                info: [
                    "HTML"
                ],
                scale: 1
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x - 2,
                    curvePoints[5].y - 0.5,
                    curvePoints[5].z - 215
                ),
                direction: "center",
                rotate: 0.2,
                type: [0],
                info: [
                    "CSS"
                ],
                scale: 1
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x - 6,
                    curvePoints[5].y,
                    curvePoints[5].z - 260
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "Tailwind"
                ],
                scale: 2.5
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[5].x + 1,
                    curvePoints[5].y + 1,
                    curvePoints[5].z - 270
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "Framer"
                ],
                scale: 1
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[6].x - 4,
                    curvePoints[6].y,
                    curvePoints[6].z - 15
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "Firebase"
                ],
                scale: 2
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[6].x + 1.5,
                    curvePoints[6].y - 0.5,
                    curvePoints[6].z - 30
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "JQuery"
                ],
                scale: 1
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[6].x - 2,
                    curvePoints[6].y,
                    curvePoints[6].z - 60
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "Git"
                ],
                scale: 2
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[6].x + 1,
                    curvePoints[6].y - 1,
                    curvePoints[6].z - 95
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "Bash"
                ],
                scale: 1
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[6].x - 4,
                    curvePoints[6].y + 0.5,
                    curvePoints[6].z - 135
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "NodeJS"
                ],
                scale: 2
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[6].x + 4,
                    curvePoints[6].y + 0,
                    curvePoints[6].z - 170
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "Haskell"
                ],
                scale: 1
            },
            {
                cameraRailDist: 0,
                position: new THREE.Vector3(
                    curvePoints[6].x - 4,
                    curvePoints[6].y - 1,
                    curvePoints[6].z - 180
                ),
                direction: "center",
                rotate: 0.1,
                type: [0],
                info: [
                    "C / C++"
                ],
                scale: 1
            },
        ]
    }, []);

    // RENDER THE CLOUDS HERE
    const clouds = useMemo(() => {
        return [
            {
                position: new THREE.Vector3(-2, -0.5, 1),
                scale: new THREE.Vector3(0.5, 0.5, 0.5)
            },
            {
                position: new THREE.Vector3(-2, 0, -2),
                scale: new THREE.Vector3(0.5, 0.5, 0.5),
                rotation: new THREE.Euler(0, Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(1.7, -0.5, -1),
                scale: new THREE.Vector3(0.5, 0.5, 0.5)
            },
            {
                position: new THREE.Vector3(1.5, -0.5, -15),
                scale: new THREE.Vector3(0.8, 0.6, 0.5),
                rotation: new THREE.Euler(0, Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(4, 5, -69),
                scale: new THREE.Vector3(2, 1.6, 1),
            },
            {
                position: new THREE.Vector3(-8, -7, -56),
                scale: new THREE.Vector3(2, 1.6, 1),
                rotation: new THREE.Euler(0, Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(-17, 10, -56),
                scale: new THREE.Vector3(2, 1.6, 1),
                rotation: new THREE.Euler(0, Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(-12, 2, -80),
                scale: new THREE.Vector3(1, 1.6, 2),
                rotation: new THREE.Euler(0, -Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(20, -3, -80),
                scale: new THREE.Vector3(4, 3, 2),
                rotation: new THREE.Euler(0, -Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(-4, -9, -120),
                scale: new THREE.Vector3(4, 3, 2),
                rotation: new THREE.Euler(0, Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(-20, 7, -150),
                scale: new THREE.Vector3(2, 1.6, 1),
                rotation: new THREE.Euler(0, Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(-12, -4, -140),
                scale: new THREE.Vector3(1.6, 1.6, 1),
                rotation: new THREE.Euler(0, Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(-12, -2, -220),
                scale: new THREE.Vector3(1.6, 1.6, 1),
                rotation: new THREE.Euler(0, Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(-5, 3, -190),
                scale: new THREE.Vector3(1.6, 1.6, 1),
            },
            {
                position: new THREE.Vector3(6, -5, -250),
                scale: new THREE.Vector3(3, 3, 2),
            },
            {
                position: new THREE.Vector3(3, 3, -290),
                scale: new THREE.Vector3(2, 2, 2),
            },
            {
                position: new THREE.Vector3(35, -9, -350),
                scale: new THREE.Vector3(4, 3, 2),
                rotation: new THREE.Euler(0, -Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(0, -3, -350),
                scale: new THREE.Vector3(3, 3, 2),
                rotation: new THREE.Euler(0, -Math.PI / 6, 0)
            },
            {
                position: new THREE.Vector3(15, 6, -370),
                scale: new THREE.Vector3(3, 3, 2),
                rotation: new THREE.Euler(0, -Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(55, -4, -400),
                scale: new THREE.Vector3(3, 3, 2),
                rotation: new THREE.Euler(0, -Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(60, -3, -420),
                scale: new THREE.Vector3(3, 3.5, 2.5),
                rotation: new THREE.Euler(0, -Math.PI / 9, 0)
            },
            {
                position: new THREE.Vector3(25, -3, -400),
                scale: new THREE.Vector3(3, 3, 2),
                rotation: new THREE.Euler(0, Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(78, -10, -480),
                scale: new THREE.Vector3(4, 4, 3),
                rotation: new THREE.Euler(Math.PI / 8, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(75, 4, -520),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(110, -1, -530),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(110, -10, -590),
                scale: new THREE.Vector3(4, 4, 3),
                rotation: new THREE.Euler(Math.PI / 8, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(90, 1, -650),
                scale: new THREE.Vector3(2, 2, 1),
                rotation: new THREE.Euler(0, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(120, 5, -610),
                scale: new THREE.Vector3(4, 4, 3),
                rotation: new THREE.Euler(0, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(60, -3, -650),
                scale: new THREE.Vector3(4, 4, 3),
                rotation: new THREE.Euler(0, Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(25, -3, -700),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(15, 7, -750),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(8, -10, -740),
                scale: new THREE.Vector3(4, 4, 3),
                rotation: new THREE.Euler(0, 0, 0)
            },
            {
                position: new THREE.Vector3(-35, 3, -800),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(-65, -1, -780),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(-85, -10, -840),
                scale: new THREE.Vector3(4, 4, 3),
                rotation: new THREE.Euler(0, Math.PI/3, 0)
            },
            {
                position: new THREE.Vector3(-115, 3, -880),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(-90, -1, -900),
                scale: new THREE.Vector3(2, 2, 2),
                rotation: new THREE.Euler(0, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(-90, -4, -920),
                scale: new THREE.Vector3(2, 2, 2),
                rotation: new THREE.Euler(0, Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(-108, -2, -920),
                scale: new THREE.Vector3(2, 2, 2),
                rotation: new THREE.Euler(0, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(-104, -2, -930),
                scale: new THREE.Vector3(1, 1, 2),
                rotation: new THREE.Euler(0, 0, 0)
            },
            {
                position: new THREE.Vector3(-97, 7, -940),
                scale: new THREE.Vector3(2.5, 2.5, 2.5),
                rotation: new THREE.Euler(0, Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(-77, 3, -980),
                scale: new THREE.Vector3(2, 2, 2),
                rotation: new THREE.Euler(0, -Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(-60, -10, -1000),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, -Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(-50, 1, -1020),
                scale: new THREE.Vector3(2, 2, 2),
                rotation: new THREE.Euler(0, -Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(-10, -2, -1030),
                scale: new THREE.Vector3(2, 2, 2),
                rotation: new THREE.Euler(0, -Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(-15, 9, -1050),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(20, -10, -1080),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, Math.PI / 2, 0)
            },
            {
                position: new THREE.Vector3(52, -1, -1100),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, -Math.PI / 7, 0)
            },
            {
                position: new THREE.Vector3(55, -4, -1140),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, -Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(100, 8, -1170),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, -Math.PI / 3, 0)
            },
            {
                position: new THREE.Vector3(100, -8, -1220),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, -Math.PI / 8, 0)
            },
            {
                position: new THREE.Vector3(140, 2, -1230),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, -Math.PI / 8, 0)
            },
            {
                position: new THREE.Vector3(110, 0, -1260),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(84, -4, -1270),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(94, 3, -1300),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, -Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(74, -10, -1340),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(74, -10, -1340),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(58, 2, -1380),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(28, 1, -1390),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, 0, 0)
            },
            {
                position: new THREE.Vector3(35, -3, -1375),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(48, -1, -1395),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, Math.PI / 5, 0)
            },
            {
                position: new THREE.Vector3(14, -10, -1420),
                scale: new THREE.Vector3(3, 3, 3),
                rotation: new THREE.Euler(0, Math.PI / 4, 0)
            },
            {
                position: new THREE.Vector3(25, 7, -1420),
                scale: new THREE.Vector3(2, 2, 3),
                rotation: new THREE.Euler(0, 0, 0)
            },
        ]
    }, []);

    const linePoints = useMemo(() => {
        return curve.getPoints(LINE_NB_POINTS);
    }, [curve]);

    const shape = useMemo(() => {
        const shape = new THREE.Shape();
        shape.moveTo(0, -0.08);
        shape.lineTo(0, 0.08);
    
        return shape;
    }, [curve]);

    const cameraGroup = useRef();
    const cameraRail = useRef();
    const camera = useRef();
    const scroll = useScroll();
    setScrollData(scroll);
    const lastScroll = useRef(0);

    const { play, setHasScroll, end, setEnd, reset, setReset } = usePlay();

    useFrame((_state, delta) => { 
        setScrollData(scroll);
        if (viewportWidth >= 700) {
            // we are in landscape mode  
            camera.current.fov = 30;
            camera.current.position.z = 5;
        }
        else {
            // we are in portrait mode
            camera.current.fov = 90;
            camera.current.position.z = 2;
        }
        
        const scrollOffset = Math.max(0, scrollData.offset);

        if (lastScroll.current <= 0 && scrollData.offset > 0) {
            setHasScroll(true);
        }

        lineMaterialRef.current.opacity = sceneOpacity.current;

        if (play && !end && sceneOpacity.current < 1) {
            sceneOpacity.current = THREE.MathUtils.lerp(
                sceneOpacity.current,
                1,
                delta * 2
            )
        }

        if (end && sceneOpacity.current > 0) {
            sceneOpacity.current = THREE.MathUtils.lerp(
                sceneOpacity.current,
                0,
                delta
            );
        }

        if (end) {
            return;
        }

        let friction = 1;
        let resetCameraRail = true;

        // move camera closer to text sections
        textSections.forEach((textSection) => {
            const distance = textSection.position.distanceTo(
                cameraGroup.current.position
            );

            if (distance < FRICTION_DISTANCE) {
                friction = Math.max(distance / FRICTION_DISTANCE, 0.1);
                const targetCameraRailPosition = new THREE.Vector3(
                    (1 - distance / FRICTION_DISTANCE) * textSection.cameraRailDist,
                    0,
                    0
                );
                cameraRail.current.position.lerp(targetCameraRailPosition, delta);
                resetCameraRail = false;
              }
        });
        if (resetCameraRail) {
            const targetCameraRailPosition = new THREE.Vector3(0, 0, 0);
            cameraRail.current.position.lerp(targetCameraRailPosition, delta);
        }

        // calculate lerped scroll offset (wtf is lerp bro)
        let lerpedScrollOffset = THREE.MathUtils.lerp(
            lastScroll.current,
            scrollOffset,
            delta * friction
        );

        // bound by 0 and 1
        lerpedScrollOffset = Math.min(lerpedScrollOffset, 1);
        lerpedScrollOffset = Math.max(lerpedScrollOffset, 0);

        lastScroll.current = lerpedScrollOffset;

        tl.current.seek(lerpedScrollOffset * tl.current.duration());

        // for lerp scroll, this is lerpedScrollOffset ----------------------- 
        const curPoint = curve.getPoint(scrollOffset);

        // follow the curve points
        cameraGroup.current.position.lerp(curPoint, delta * 24);

        // make the group look ahead on the curve
        // for lerp scroll, this is lerpedScrollOffset ----------------------- 
        const lookAtPoint = curve.getPoint(Math.min(scrollOffset + CURVE_AHEAD_CAMERA, 1));
        const currentLookAt = cameraGroup.current.getWorldDirection(
            new THREE.Vector3()
        );
        const targetLookAt = new THREE.Vector3().subVectors(curPoint, lookAtPoint).normalize();
        const lookAt = currentLookAt.lerp(targetLookAt, delta * 24);
        cameraGroup.current.lookAt(
            cameraGroup.current.position.clone().add(lookAt)
        )

        // airplane rotation
        // for lerp scroll, this is lerpedScrollOffset ----------------------- 
        const tangent = curve.getTangent(scrollOffset + CURVE_AHEAD_AIRPLANE);

        const nonLerpLookAt = new THREE.Group();
        nonLerpLookAt.position.copy(curPoint);
        nonLerpLookAt.lookAt(nonLerpLookAt.position.clone().add(targetLookAt));

        tangent.applyAxisAngle(
            new THREE.Vector3(0, 1, 0),
            -nonLerpLookAt.rotation.y
        )

        let angle = Math.atan2(-tangent.z, tangent.x);
        angle = -Math.PI / 2 + angle;

        let angleDegrees = (angle * 180) / Math.PI;
        angleDegrees *= 2.4; // make the airplane rotation effect stronger

        // LIMITING THE PLANE ANGLE HERE
        if (angleDegrees < 0) {
            angleDegrees = Math.max(angleDegrees, -AIRPLANE_MAX_ANGLE);
        }
        if (angleDegrees > 0) {
            angleDegrees = Math.min(angleDegrees, AIRPLANE_MAX_ANGLE);
        }

        // setting the angle back
        angle = (angleDegrees * Math.PI) / 180;

        const targetAirplaneQuaternion = new THREE.Quaternion().setFromEuler(
            new THREE.Euler(
                airplane.current.rotation.x,
                airplane.current.rotation.y,
                angle
            )
        )
        airplane.current.quaternion.slerp(targetAirplaneQuaternion, delta * 2);

        if (cameraGroup.current.position.z < curvePoints[curvePoints.length - 1].z + 100) {
            setEnd(true);
            planeOutTl.current.play();
        }
    });

    const airplane = useRef();

    const tl = useRef();
    const backgroundColors = useRef({
        colorA: "#3535cc",
        colorB: "#abaadd"
    });

    const planeInTl = useRef();
    const planeOutTl = useRef();

    useLayoutEffect(() => {
        tl.current = gsap.timeline();
    
        tl.current.to(backgroundColors.current, {
            duration: 1,
            colorA: "#6f35cc",
            colorB: "#d4a153",
        });
        tl.current.to(backgroundColors.current, {
            duration: 1,
            colorA: "#424242",
            colorB: "#cccc77",
        });
        tl.current.to(backgroundColors.current, {
            duration: 1,
            colorA: "#81318b",
            colorB: "#55ab8f",
        });
    
        tl.current.pause();

        planeInTl.current = gsap.timeline();
        planeInTl.current.pause();
        planeInTl.current.from(airplane.current.position, {
            duration: 3,
            z: 5,
            y: -2,
        });

        planeOutTl.current = gsap.timeline();
        planeOutTl.current.pause();

        planeOutTl.current.to(
            airplane.current.position,
            {
                duration: 10,
                z: -250,
                y: 10,
            },
            0
        );
        planeOutTl.current.to(
            cameraRail.current.position,
            {
                duration: 8,
                y: 12,
            },
            0
        );
        planeOutTl.current.to(airplane.current.position, {
            duration: 1,
            z: -1000,
        })
    }, []);

    useEffect(() => {
        if (play) {
            planeInTl.current.play();
        }
    }, [play]);

    const python = {
        position: new THREE.Vector3(17, 0, -1450),
        scale: new THREE.Vector3(3, 3, 3),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const java = {
        position: new THREE.Vector3(-2, 2, -1480),
        scale: new THREE.Vector3(4, 4, 4),
        rotation: new THREE.Euler(Math.PI / 2, 0, -Math.PI / 8)
    }

    const js = {
        position: new THREE.Vector3(-3, -1, -1550),
        scale: new THREE.Vector3(3, 3, 3),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const ts = {
        position: new THREE.Vector3(-12, 1, -1600),
        scale: new THREE.Vector3(3, 3, 3),
        rotation: new THREE.Euler(Math.PI / 2 - Math.PI / 80, 0, 0)
    }

    const reactLogo = {
        position: new THREE.Vector3(-4, -1, -1630),
        scale: new THREE.Vector3(5, 5, 5),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const next = {
        position: new THREE.Vector3(-10, 1, -1650),
        scale: new THREE.Vector3(6, 6, 6),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const html = {
        position: new THREE.Vector3(1, 0, -1700),
        scale: new THREE.Vector3(2, 2, 2),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const css = {
        position: new THREE.Vector3(-8, 0, -1710),
        scale: new THREE.Vector3(2, 2, 2),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const tailwind = {
        position: new THREE.Vector3(-4, 1, -1740),
        scale: new THREE.Vector3(3, 3, 3),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const framer = {
        position: new THREE.Vector3(-6, -1, -1800),
        scale: new THREE.Vector3(3, 3, 3),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const firebase = {
        position: new THREE.Vector3(1, 2, -1820),
        scale: new THREE.Vector3(2.5, 2.5, 2.5),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const jquery = {
        position: new THREE.Vector3(-6, 3, -1850),
        scale: new THREE.Vector3(2.5, 2.5, 2.5),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const git = {
        position: new THREE.Vector3(3, 2, -1880),
        scale: new THREE.Vector3(2.5, 2.5, 2.5),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const bash = {
        position: new THREE.Vector3(-3, 0, -1905),
        scale: new THREE.Vector3(12, 12, 12),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const node = {
        position: new THREE.Vector3(1, 2, -1925),
        scale: new THREE.Vector3(3, 3, 3),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const haskell = {
        position: new THREE.Vector3(-7, 0, -1960),
        scale: new THREE.Vector3(2.5, 2.5, 2.5),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }

    const c = {
        position: new THREE.Vector3(3, 0, -2000),
        scale: new THREE.Vector3(20, 20, 20),
        rotation: new THREE.Euler(Math.PI / 2, 0, 0)
    }


    // THIS IS HOW YOU CHANGE THE SCROLL POSITION
    // probably make a context and send it thru to the navbar
    // setTimeout(() => {
    //     scroll.el.scrollTop = scroll.el.scrollHeight * 0.5;
    //     console.log("done scrolling here");
    // }, 7000);

    return useMemo(() => (
        <>
            {/* <OrbitControls /> */}
            <group ref={cameraGroup}>
                <Background backgroundColors={backgroundColors}/>
                <group ref={cameraRail}>
                    <PerspectiveCamera 
                        ref={camera} 
                        position={viewportWidth >= 700 ? [0, 0, 5] : [0, 0, 2]} 
                        fov={viewportWidth >= 700 ? 30 : 90} 
                        makeDefault 
                    />
                </group>
                <group ref={airplane}>
                    <Float floatIntensity={1} speed={1.5} rotationIntensity={0.5}>
                        <Airplane 
                            rotation-y={Math.PI} 
                            position-y={-0.1} 
                            scale={viewportWidth <= 480 ? [0.07, 0.07, 0.07] : [0.15, 0.15, 0.15]} 
                        />
                    </Float>
                </group>
            </group>

            {/* TEXT */}
            {textSections.map((textSection, index) => {
                return (
                    <TextSection {...textSection} key={index} />
                )
            })}

            {/* LINE */}
            <group position-y={-2}>
                <mesh>
                    <extrudeGeometry
                        args={[
                            shape,
                            {
                                steps: LINE_NB_POINTS,
                                bevelEnabled: false,
                                extrudePath: curve,
                            }
                        ]}
                    />
                    <meshStandardMaterial 
                        color="white" 
                        opacity={1} 
                        ref={lineMaterialRef}
                        transparent
                        envMapIntensity={2}
                        onBeforeCompile={fadeOnBeforeCompile}
                    />
                </mesh>
            </group>

            {/* CLOUDS */}
            {clouds.map((cloud, index) => (
                <Cloud sceneOpacity={sceneOpacity} {...cloud} key={index} />  
            ))}

            {/* LOGOS */}
            <Python sceneOpacity={sceneOpacity} {...python} opacity={1} />
            <Java sceneOpacity={sceneOpacity} {...java} opacity={1} />
            <Javascript sceneOpacity={sceneOpacity} {...js} opacity={1} />
            <Typescript sceneOpacity={sceneOpacity} {...ts} opacity={1} />
            <ReactLogo sceneOpacity={sceneOpacity} {...reactLogo} opacity={1} />
            <Next sceneOpacity={sceneOpacity} {...next} opacity={1} />
            <Html sceneOpacity={sceneOpacity} {...html} opacity={1} />
            <Css sceneOpacity={sceneOpacity} {...css} opacity={1} />
            <Tailwind sceneOpacity={sceneOpacity} {...tailwind} opacity={1} />
            <Framer sceneOpacity={sceneOpacity} {...framer} opacity={1} />
            <Firebase sceneOpacity={sceneOpacity} {...firebase} opacity={1} />
            <Jquery sceneOpacity={sceneOpacity} {...jquery} opacity={1} />
            <Git sceneOpacity={sceneOpacity} {...git} opacity={1} />
            <Bash sceneOpacity={sceneOpacity} {...bash} opacity={1} />
            <Node sceneOpacity={sceneOpacity} {...node} opacity={1} />
            <Haskell sceneOpacity={sceneOpacity} {...haskell} opacity={1} />
            <C sceneOpacity={sceneOpacity} {...c} opacity={1} />
        </>
    ), []);
};