import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Airplane(props) {
  const { nodes, materials } = useGLTF('./models/Airplane.glb')
  return (
    <>
        <directionalLight position={[0, 3, 1]} intensity={0.1} />
        <group {...props} dispose={null}>
            <group scale={0.011}>
                <mesh geometry={nodes.Mesh.geometry} material={materials.Mat} />
                <mesh geometry={nodes.Mesh_1.geometry} material={materials['Mat.1']} />
            </group>
        </group>
    </>
  )
}

useGLTF.preload('./models/Airplane.glb')
