import React, { useEffect } from "react";
import { usePlay } from "../contexts/Play";

export default function Navbar({ scrollData }) {
    const { end, play } = usePlay();

    function scrollToEducation() {
        if (scrollData.el) {
            scrollData.el.scrollTop = 0;
        }
    }

    function scrollToExperience() {
        if (scrollData.el) {
            scrollData.el.scrollTop = scrollData.el.scrollHeight * 0.175;
        }
    }

    function scrollToProjects() {
        if (scrollData.el) {
            scrollData.el.scrollTop = scrollData.el.scrollHeight * 0.415;
        }
    }

    function scrollToSkills() {
        if (scrollData.el) {
            scrollData.el.scrollTop = scrollData.el.scrollHeight * 0.625;
        }
    }

    if (play) {
        return (
            <div className={`navbar ${end ? "disappear" : "navbar-fade-in"}`}>
                <div className="navbar-group">
                    <p className="navbar-link" onClick={() => scrollToEducation()}>EDUCATION</p>
                    <p className="navbar-link" onClick={() => scrollToExperience()}>EXPERIENCE</p>
                </div>
                <div className="navbar-group">
                    <p className="navbar-link" onClick={() => scrollToProjects()}>PROJECTS</p>
                    <p className="navbar-link" onClick={() => scrollToSkills()}>MY SKILLS</p>
                </div>
            </div>
        )
    }
    else {
        return <></>
    }
}

// THIS IS HOW YOU CHANGE THE SCROLL POSITION
// probably make a context and send it thru to the navbar
// setTimeout(() => {
//     scroll.el.scrollTop = scroll.el.scrollHeight * 0.5;
//     console.log("done scrolling here");
// }, 7000);