import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { fadeOnBeforeCompile } from '../utils/fadeMaterial'
import { useFrame } from '@react-three/fiber'

export function Tailwind({ sceneOpacity, opacity, ...props }) {
    const { nodes, materials } = useGLTF('./models/tailwind.glb');

    const materialRef = useRef();

    useFrame(() => {
        materialRef.current.opacity = sceneOpacity.current;
    });

    return (
        <group {...props} dispose={null}>
            <mesh geometry={nodes.Curve.geometry} material={materials['SVGMat.035']} scale={85.125} >
                <meshStandardMaterial  
                    ref={materialRef}
                    onBeforeCompile={fadeOnBeforeCompile}
                    envMapIntensity={2}
                    transparent 
                    opacity={opacity}
                />
            </mesh>
        </group>
    )
}

useGLTF.preload('./models/tailwind.glb')
