import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { fadeOnBeforeCompile } from '../utils/fadeMaterial'
import { useFrame } from '@react-three/fiber'

export function Next({ opacity, sceneOpacity, ...props }) {
  const { nodes, materials } = useGLTF('./models/next.glb');

  const materialRef = useRef();

  useFrame(() => {
    materialRef.current.opacity = sceneOpacity.current;
  });

  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Curve.geometry} material={materials['SVGMat.028']} scale={2.348} >
        <meshStandardMaterial  
          ref={materialRef}
          onBeforeCompile={fadeOnBeforeCompile}
          envMapIntensity={2}
          transparent 
          opacity={opacity}
        />
      </mesh>
      <mesh geometry={nodes.Curve001.geometry} material={materials['SVGMat.028']} >
        <meshStandardMaterial  
          ref={materialRef}
          onBeforeCompile={fadeOnBeforeCompile}
          envMapIntensity={2}
          transparent 
          opacity={opacity}
        />
      </mesh>
    </group>
  )
}

useGLTF.preload('./models/next.glb')
